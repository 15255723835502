import { ref } from "vue";
import { EnumApprovalState } from "@/models/enum/Enum";
import { ApprovalSearchRequest } from "@/models/approval/ApprovalRequest";

const initForm = (): ApprovalSearchRequest => ({
  approvals: [EnumApprovalState.WAITING]
});

const form = ref(initForm());

export const useApproval = () => {
  const getForm = () => form.value;
  const setForm = (val: ApprovalSearchRequest) => (form.value = val);
  const resetForm = () => (form.value = initForm());
  const setAccountant = () =>
    (form.value = {
      approvals: [EnumApprovalState.WAITING, EnumApprovalState.UNAPPROVAL]
    });
  const setAllCountForm = () =>
    (form.value = {
      approvals: [
        EnumApprovalState.WAITING,
        EnumApprovalState.UNAPPROVAL,
        EnumApprovalState.DURING
      ]
    });

  return {
    getForm,
    setForm,
    resetForm,
    setAllCountForm,
    setAccountant
  };
};
