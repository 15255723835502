
import Vue, { PropType } from "vue";
import { DataTableHeader } from "vuetify";
import ApprovalChip from "./ApprovalChip.vue";
import { Shozoku } from "@/models/transaction/Shozoku";
import DatePicker from "@/components/picker/DatePicker.vue";
import { DateTime } from "luxon";
import { VacationDaysDatKey } from "@/models/transaction/VacationDaysDatKey";
import { EnumApprovalState } from "@/models/enum/Enum";

type ItemsType = {
  user: string;
  workspace: string;
  approval: string;
  approvalCode: number;
};

type ClickRowSlot = {
  expand: (value: boolean) => void;
  headers: DataTableHeader[];
  isExpanded: boolean;
  isMobile: boolean;
  isSelected: boolean;
  item: unknown;
  select: (value: boolean) => void;
};

const approvalFilter = (item: ItemsType, approval: number | undefined) => {
  return approval ? item.approvalCode === approval : true;
};

const approvalItems = [
  { text: "未承認", value: EnumApprovalState.UNAPPROVAL },
  { text: "承認", value: EnumApprovalState.RESOLVE },
  { text: "却下", value: EnumApprovalState.REJECT },
  { text: "承認中", value: EnumApprovalState.DURING },
  { text: "承認待ち", value: EnumApprovalState.WAITING }
];

export type HeaderType = {
  key: string;
  date: string;
  user: string;
  affiliation: string;
  approval: string;
};

export class ApprovalTableForm {
  dateFrom?: string | null;
  dateTo?: string | null;
  employeeIds?: number[];
  affiliationIds?: number[];
  approvals?: EnumApprovalState[];

  constructor(props?: ApprovalTableForm) {
    if (props) {
      this.dateFrom = props.dateFrom;
      this.dateTo = props.dateTo;
      this.employeeIds = props.employeeIds;
      this.affiliationIds = props.affiliationIds;
      this.approvals = props.approvals;
      return;
    }
  }
}

type Item = {
  value: number;
  text: string;
};

export default Vue.extend({
  name: "ApprovalTableBase",
  components: { ApprovalChip, DatePicker },
  props: {
    items: {
      type: Array,
      required: true
    },
    header: {
      type: Object as PropType<HeaderType>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    form: {
      type: Object as PropType<ApprovalTableForm>,
      required: false,
      default: () => new ApprovalTableForm()
    },
    selected: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: "",
      searchApproval: undefined as number | undefined,
      expanded: [] as any[],
      approvalItems,
      affiliationItems: [] as Shozoku[],
      employeeItems: [] as Item[]
    };
  },
  computed: {
    modelForm: {
      get(): ApprovalTableForm {
        return this.form;
      },
      set(newValue: ApprovalTableForm) {
        this.$emit("update:form", newValue);
      }
    },
    headers: {
      get(): DataTableHeader[] {
        const { date, user, affiliation, approval } = this.header;
        return [
          { text: "申請日", value: "requestDate", divider: true },
          { text: "対象日付", value: date, divider: true },
          { text: "社員コード", value: "employeeCode", divider: true },
          { text: "社員名", value: user, divider: true },
          { text: "所属名", value: affiliation, divider: true },
          {
            text: "承認状態",
            value: approval,
            align: "center",
            divider: true,
            sortable: false,
            width: 100,
            filter: (_: unknown, __: unknown, item: ItemsType) =>
              approvalFilter(item, this.searchApproval)
          },
          {
            text: "承認",
            value: "resolve",
            align: "center",
            sortable: false,
            divider: true,
            width: 50
          },
          {
            text: "却下",
            value: "reject",
            align: "center",
            sortable: false,
            divider: true,
            width: 50
          },
          { text: "", value: "data-table-expand" }
        ];
      }
    }
  },
  methods: {
    onClear() {
      this.modelForm = new ApprovalTableForm();
      this.$emit("search");
    },
    async getAffiliationItems() {
      const result = await this.$get<Shozoku[]>(
        this.Paths.approval.affiliation
      );
      if (result) this.affiliationItems = result;
    },
    async getEmployeeItems() {
      const result = await this.$get<Item[]>(this.Paths.approval.employee);
      if (result) this.employeeItems = result;
    },
    formatDate(date: string | VacationDaysDatKey[]) {
      if (Array.isArray(date)) {
        const returndate = [""];
        for (let i = 0; i < date.length; i++) {
          const d = DateTime.fromISO(date[i].date);
          const week = ["", "月", "火", "水", "木", "金", "土", "日"];
          const w = d.weekday;
          console.log("w", w);
          const weekday = "(" + week[w] + ")";
          console.log("weekday", weekday);
          if (i > 0) {
            returndate[0] = returndate[0] + "\n";
          }
          returndate[0] =
            returndate[0] +
            DateTime.fromISO(date[i].date).toFormat("yyyy年MM月dd日" + weekday);
        }
        return returndate[0];
        // return date
        //   .map(e => DateTime.fromISO(e.date).toFormat("yyyy年MM月dd日"))
        //   .join("\n");
      } else {
        const d = DateTime.fromISO(date);
        // 曜日情報を作成
        const week = ["", "月", "火", "水", "木", "金", "土", "日"];
        const w = d.weekday;
        console.log("w", w);
        const weekday = "(" + week[w] + ")";
        console.log("weekday", weekday);
        return DateTime.fromISO(date).toFormat("yyyy年MM月dd日" + weekday);
      }
    },
    onClickRow(_: unknown, slot: ClickRowSlot) {
      slot.expand(!slot.isExpanded);
    },
    itemClass(item?: any) {
      let color = item.remarks ? "blue lighten-4" : "";
      const isRemarks = item.remarks ? true : false;
      if (isRemarks) color = "blue lighten-4";
      const isGreenColor =
        item.aid || item.oid || item.sid || item.vid ? true : false;
      if (isGreenColor) color = "green lighten-4";
      if (isRemarks && isGreenColor) color = "red lighten-4";
      const isExpand = this.expanded.some(e => e.recordId === item?.recordId);
      if (isExpand) color = "yellow accent-1";
      return color;
    }
  },
  async mounted() {
    await Promise.all([this.getEmployeeItems(), this.getAffiliationItems()]);
  }
});
